import Vue from 'vue';
import Vuex from 'vuex';

import login from '@/store/modules/login'
import measureDetail from '@/store/modules/measureDetail'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    login,
    measureDetail
  }
});
