import Axios from 'axios' //在http.js引入axios
import { setAppToken ,getAppToken} from './utils/auth';
import { test_url, production_url, develop_url } from '../environment'

let ConfigBaseURL = null

switch (process.env.NODE_ENV) {
case 'development':
  ConfigBaseURL = develop_url //这里是本地的请求
  break
case 'production': //测试或者生产
  if (process.env.VUE_APP_TITLE === 'test') {
    ConfigBaseURL = production_url //测试环境
  } else {
    ConfigBaseURL = production_url //生产环境
  }
  break;
default:
  break;
}

//使用create方法创建axios实例
const service = Axios.create({
  timeout: 60000, // 请求超时时间
  baseURL: ConfigBaseURL,
  method: 'post',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})

service.interceptors.request.use(
  (config) => {
    config.headers.authorization = JSON.parse(getAppToken())?.['access-token'];
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
)

// 添加响应拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code === 0) {
      return response.data.data;
    }
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      const query = { 'refresh-token': JSON.parse(getAppToken())?.['refresh-token']};
      return Axios.post(
        `${ConfigBaseURL}/admin/public/auth/refresh-token`,
        query
      ).then((res) => {
        setAppToken(res.data.data);
        const { url, method, params } = error.response.config;
        return service({ url, method, params });
      }).catch((err) => { // refresh-token 错误处理
        if (err.response.status === 400) {
          location.href = `${window.location.origin}/login`
        }
      } );
    }
    const token = JSON.parse(getAppToken())?.['access-token'];
    if (!token) {
      location.href = `${window.location.origin}/login`
    }
  }
);

export default service

