import Vue from 'vue';
import VueRouter from 'vue-router';
import VPageLayout from '@/components/VPageLayout/index.vue'


// 处理vue-router@3.0以上 跳转路由相同页面，报错问题
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch(err => err);
};


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/login'
  },
  // // 首页
  // {
  //   path: '/index',
  //   name: 'index',
  //   meta: {
  //     title: '首页'
  //   }
  //   // component: () => import('@/views/index/index.vue')
  // },
  // 登录
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录'
    },
    component: () => import('@/views/login/index.vue')
  },
  // 修改密码页面
  {
    path: '/editPassword',
    name: 'editPassword',
    meta: {
      title: '修改密码'
    },
    component: () => import('@/views/login/editPassword.vue')
  },
  // 量体页面首页
  {
    path: '/measure',
    name: 'measure',
    meta: {
      title: '量体首页'
    },
    component: VPageLayout,
    redirect: { name: 'measureIndex' },
    children: [
      {
        path: 'measureIndex',
        name: 'measureIndex',
        component: () => import('@/views/index/index.vue')
      },
      {
        path: 'measureDetail',
        name: 'measureDetail',
        component: () => import('@/views/measureDetail/index.vue')
      },
      {
        path: 'roster',
        name: 'roster',
        component: () => import('@/views/roster/index.vue')
      },
      {
        path: 'measureData',
        name: 'measureData',
        component: () => import('@/views/measureData/newIndex.vue')
      },
      {
        path: 'personalCenter',
        name: 'personalCenter',
        component: () => import('@/views/personalCenter/index.vue')
      }
    ]
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
