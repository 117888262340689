import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store/index';
import axios from './axios';
import VueClipboard from 'vue-clipboard2'
import { Message, Loading, Notification, MessageBox } from 'element-ui'
// import element from './components/element'
import ElementUI from 'element-ui'; // 2.1引入结构
import 'element-ui/lib/theme-chalk/index.css'; // 2.2引入样式
import '@/assets/css/common.css';

import VueAMap from 'vue-amap';
import '@vuemap/vue-amap/dist/style.css'

Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  //申请地址 https://lbs.amap.com/ 选择web端jsAPI
  key: '2497e5a7b51952a546c10ba6c1d0f07c',
  // 插件集合，用到什么插件就使用什么插件
  plugin: [
    'AMap.CircleEditor',
    'AMap.Geolocation', // 定位控件，用来获取和展示用户主机所在的经纬度位置
    'AMap.Geocoder',
    'AMap.Autocomplete',
    'AMap.PlaceSearch',
    'AMap.CitySearch'
  ],
  v: '1.4.15' // 高德sdk版本，最新的sdk已经出到了2.0
  //官网解释：JSAPI 2.0 提供的开发接口与 1.4 版本达到 99%的兼容度，但是为了保证插件的稳定性我们还是选择1.4.4。
})

Vue.config.productionTip = false;

Vue.use(VueClipboard)

Vue.use(ElementUI);
// 引入element组件
// Vue.use(element)
// 引入v-loading
Vue.use(Loading.directive)
// 引入element指令组件，例： this.$message
Vue.prototype.$message = Message
Vue.prototype.$loading = Loading.service
Vue.prototype.$notify = Notification
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$alert = MessageBox.alert


Vue.prototype.$axios = axios;


// // 在此处进行配置全局的路由守卫（全局前置钩子）
// // 目的是：判断当前用户中本地存储是否有token，使用户在登录之前，只能访问到/login页面，其他的页面是访问不到的。
// router.beforeEach((to, from, next) => {
//   // to 即将进入的路由
//   // from 在哪个路由进入的
//   // next 放行
//   //   console.log(to); //打印的是页面要跳转到的路由,例如：它下面的path：为"/login"
//   let token = localStorage.getItem('token') || ''; //在本地存储中获取token
//   if (token) {
//     //判断是否有token
//     next();
//   } else {
//     //在没有token的前提下，to下面的path是否为/login，如果不是则页面跳转到登录页面
//     if (to.path === '/login') {
//       next();
//     } else {
//       next({ path: '/login' }); //跳转页面到login页
//     }
//   }
// })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
