
export function getAppToken() {
  // return Cookies.get(appToken)
  // return localStorage.getItem('appToken');
  return window.sessionStorage.getItem('appToken', JSON.stringify());
}
export function setAppToken(AppToken) {
  // return Cookies.set(appToken, AppToken)
  // return localStorage.setItem('appToken', JSON.stringify(AppToken));
  return window.sessionStorage.setItem('appToken', JSON.stringify(AppToken));
}


// login 用户信息
export function getUserInfo() {
  // return localStorage.getItem('userInfo')
  return window.sessionStorage.getItem('userInfo')
}
export function setUserInfo(userInfo) {
  // return localStorage.setItem('userInfo', userInfo)
  return window.sessionStorage.setItem('userInfo', userInfo)
}


// task 获取任务状态
export function getStatus() {
  // return localStorage.getItem('userInfo')
  return window.sessionStorage.getItem('status')
}
// 存入任务状态
export function setStatus(query) {
  // return localStorage.setItem('userInfo', userInfo)
  return window.sessionStorage.setItem('status', query)
}


// task 获取任务索引
export function getTaskIndex() {
  // return localStorage.getItem('userInfo')
  return window.sessionStorage.getItem('taskIndex')
}
// 存入任务 索引
export function setTaskIndex(query) {
  // return localStorage.setItem('userInfo', userInfo)
  return window.sessionStorage.setItem('taskIndex', query)
}

// task 获取任务信息
export function getTaskDetail() {
  // return localStorage.getItem('userInfo')
  return window.sessionStorage.getItem('taskDetail')
}
// 存入任务信息
export function setTaskDetail(query) {
  // return localStorage.setItem('userInfo', userInfo)
  return window.sessionStorage.setItem('taskDetail', query)
}

// task 获取 量体人信息
export function getPersonInfo() {
  // return localStorage.getItem('userInfo')
  return window.sessionStorage.getItem('personData')
}
// 存入 量体人信息
export function setPersonInfo(query) {
  // return localStorage.setItem('userInfo', userInfo)
  return window.sessionStorage.setItem('personData', query)
}

// // task 获取任务信息
// export function getTaskDetail() {
//   // return localStorage.getItem('userInfo')
//   return window.sessionStorage.getItem('userInfo')
// }
// // 存入任务信息
// export function setTaskDetail(query) {
//   // return localStorage.setItem('userInfo', userInfo)
//   return window.sessionStorage.setItem('taskDetail', query)
// }

// // task 获取任务信息
// export function getTaskDetail() {
//   // return localStorage.getItem('userInfo')
//   return window.sessionStorage.getItem('userInfo')
// }
// // 存入任务信息
// export function setTaskDetail(query) {
//   // return localStorage.setItem('userInfo', userInfo)
//   return window.sessionStorage.setItem('taskDetail', query)
// }