import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  state: {
    // 量体任务详情，
    detailData: null
  },
  mutations: {
    setDetailData(state, result) {
      state.detailData = result
    }
  },
  actions: {

  }
}
