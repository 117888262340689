import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  state: {
    // 登录用户信息，
    userInfo: null
  },
  mutations: {
    setUserInfo(state, result) {
      state.userInfo = result
    }
  },
  actions: {

  }
}
